import * as s from './ArBrokerHero.module.scss'
import React, { useEffect, useState } from 'react'

import { ArContainer } from 'src/components/ArContainer'
import { TarBrokerHeroProps } from './ArBrokerHero.types'
import BrokerpageScreenshot from 'src/assets/images/artificial-contract-builder-tool.png'
import BrokerpageScreenshotWebp from 'src/assets/images/artificial-contract-builder-tool.webp'
import BMSLogo from 'src/assets/images/bms-logo.png'
import ArdonaghLogo from 'src/assets/images/ardonagh-logo.png'
import GallagherLogo from 'src/assets/images/gallagher-logo.png'
import LocktonLogo from 'src/assets/images/lockton-logo.png'
import AonLogo from 'src/assets/images/aon-logo.png'
import PPLLogo from 'src/assets/images/ppl-logo.png'
import BMSLogoWebp from 'src/assets/images/bms-logo.webp'
import ArdonaghLogoWebp from 'src/assets/images/ardonagh-logo.webp'
import GallagherLogoWebp from 'src/assets/images/gallagher-logo.webp'
import LocktonLogoWebp from 'src/assets/images/lockton-logo.webp'
import AonLogoWebp from 'src/assets/images/aon-logo.webp'
import PPLLogoWebp from 'src/assets/images/ppl-logo.webp'
import { isWebpSupported } from 'src/utils/isWebpSupported'

export const ArBrokerHero: React.FC<TarBrokerHeroProps> = () => {
  const [screenshotUrl, setScreenshotUrl] = useState<string>()
  const [gallagherLogoUrl, setGallagherLogoUrl] = useState<string>()
  const [aonLogoUrl, setAonLogoUrl] = useState<string>()
  const [bmsLogoUrl, setBmsLogoUrl] = useState<string>()
  const [locktonLogoUrl, setLocktonLogoUrl] = useState<string>()
  const [pplLogoUrl, setPplLogoUrl] = useState<string>()
  const [ardonaghLogoUrl, setArdonaghLogoUrl] = useState<string>()

  useEffect(() => {
    setScreenshotUrl(isWebpSupported() ? BrokerpageScreenshotWebp : BrokerpageScreenshot)
    setGallagherLogoUrl(isWebpSupported() ? GallagherLogoWebp : GallagherLogo)
    setAonLogoUrl(isWebpSupported() ? AonLogoWebp : AonLogo)
    setBmsLogoUrl(isWebpSupported() ? BMSLogoWebp : BMSLogo)
    setLocktonLogoUrl(isWebpSupported() ? LocktonLogoWebp : LocktonLogo)
    setPplLogoUrl(isWebpSupported() ? PPLLogoWebp : PPLLogo)
    setArdonaghLogoUrl(isWebpSupported() ? ArdonaghLogoWebp : ArdonaghLogo)
  }, [])
  return (
    <ArContainer className={s.container}>
      <div className="text-center mx-auto flex flex-col gap-4">
        <div className="text-center mx-auto max-w-3xl">
          <h1>Broking, reinvented</h1>
        </div>
        <div className="max-w-xl mx-auto">
          <p>Build efficient, compliant, and future-ready contracts seamlessly with Contract Builder.</p>
        </div>
        <div className="max-w-6xl lg:py-8 py-8">
          <img
            src={screenshotUrl}
            alt="Screenshot of artificial's broker tool user interface"
            className="w-[1652px] h-[903px]"
          />
        </div>
        <h6 className="uppercase font-mono text-brandGray-500 text-sm leading-5 text-center tracking-widest">
          TRUSTED BY SOME OF THE WORLD’S BEST BROKERS
        </h6>
        <div className="max-w-5xl mx-auto grid md:grid-cols-6 grid-cols-2 pt-12 md:pt-0 justify-center items-center gap-20">
          <img
            src={gallagherLogoUrl}
            alt="Gallagher insurance logo"
            width="100%"
            height="auto"
            className="w-20 h-auto object-contain"
          />
          <img
            src={aonLogoUrl}
            alt="Aon insurance logo"
            width="100%"
            height="auto"
            className="w-28 h-auto object-contain"
          />
          <img
            src={bmsLogoUrl}
            alt="BMS insurance logo"
            width="100%"
            height="auto"
            className="w-28 h-auto object-contain"
          />
          <img
            src={locktonLogoUrl}
            alt="Lockton insurance logo"
            width="100%"
            height="auto"
            className="w-28 h-auto object-contain"
          />
          <img
            src={pplLogoUrl}
            alt="PPL insurance logo"
            width="100%"
            height="auto"
            className="w-28 h-auto object-contain"
          />
          <img
            src={ardonaghLogoUrl}
            alt="Ardonagh insurance logo"
            width="100%"
            height="auto"
            className="w-20 h-auto object-contain"
          />
        </div>
      </div>
    </ArContainer>
  )
}
