// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArBrokerCustomerLove-module--ar-h1-mobile--ZI3PD";
export var arH2Mobile = "ArBrokerCustomerLove-module--ar-h2-mobile--3Zn73";
export var arH3Mobile = "ArBrokerCustomerLove-module--ar-h3-mobile--GuX3p";
export var arH4Mobile = "ArBrokerCustomerLove-module--ar-h4-mobile--2YRlb";
export var arH5Mobile = "ArBrokerCustomerLove-module--ar-h5-mobile--309Zf";
export var arH1 = "ArBrokerCustomerLove-module--ar-h1--2bxIo";
export var arH2 = "ArBrokerCustomerLove-module--ar-h2--3b5qC";
export var arH3 = "ArBrokerCustomerLove-module--ar-h3--2aQoJ";
export var arH4 = "ArBrokerCustomerLove-module--ar-h4--2AoZf";
export var arH5 = "ArBrokerCustomerLove-module--ar-h5--13wMO";
export var arParagraphXl = "ArBrokerCustomerLove-module--ar-paragraph-xl--2d-vy";
export var arParagraphXlMedium = "ArBrokerCustomerLove-module--ar-paragraph-xl-medium--141Ex";
export var arParagraphBase = "ArBrokerCustomerLove-module--ar-paragraph-base--3IkX5";
export var arParagraphBaseMedium = "ArBrokerCustomerLove-module--ar-paragraph-base-medium--2MHyw";
export var arParagraphSmall = "ArBrokerCustomerLove-module--ar-paragraph-small--wodR6";
export var arParagraphSmallMedium = "ArBrokerCustomerLove-module--ar-paragraph-small-medium--34jAZ";
export var arParagraphXsmall = "ArBrokerCustomerLove-module--ar-paragraph-xsmall--35BEM";
export var arParagraphXsmallMedium = "ArBrokerCustomerLove-module--ar-paragraph-xsmall-medium--1W88x";
export var arLinkMobile = "ArBrokerCustomerLove-module--ar-link-mobile--3N91Q";
export var arLinkDesktop = "ArBrokerCustomerLove-module--ar-link-desktop--2VnyH";
export var arQuote = "ArBrokerCustomerLove-module--ar-quote--3ifo9";
export var arQuoteMobile = "ArBrokerCustomerLove-module--ar-quote-mobile--2awry";
export var arGradientLight = "ArBrokerCustomerLove-module--ar-gradient-light--28dGe";
export var arGradientBlue = "ArBrokerCustomerLove-module--ar-gradient-blue--2x91f";
export var arTransition = "ArBrokerCustomerLove-module--ar-transition--3Li5y";
export var container = "ArBrokerCustomerLove-module--container--295Be";
export var quotation = "ArBrokerCustomerLove-module--quotation--1P8mF";
export var logo = "ArBrokerCustomerLove-module--logo--3jFWs";
export var footer = "ArBrokerCustomerLove-module--footer--1A26Q";