// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArBrokerFeatures-module--ar-h1-mobile--2hhTA";
export var arH2Mobile = "ArBrokerFeatures-module--ar-h2-mobile--16UER";
export var arH3Mobile = "ArBrokerFeatures-module--ar-h3-mobile--AUsX5";
export var arH4Mobile = "ArBrokerFeatures-module--ar-h4-mobile--3-UJq";
export var arH5Mobile = "ArBrokerFeatures-module--ar-h5-mobile--39C0i";
export var arH1 = "ArBrokerFeatures-module--ar-h1--pfaIU";
export var arH2 = "ArBrokerFeatures-module--ar-h2--KTBV9";
export var arH3 = "ArBrokerFeatures-module--ar-h3--ELfR3";
export var arH4 = "ArBrokerFeatures-module--ar-h4--8G4vJ";
export var arH5 = "ArBrokerFeatures-module--ar-h5--bjxKD";
export var arParagraphXl = "ArBrokerFeatures-module--ar-paragraph-xl--3rHzY";
export var arParagraphXlMedium = "ArBrokerFeatures-module--ar-paragraph-xl-medium--2s9IW";
export var arParagraphBase = "ArBrokerFeatures-module--ar-paragraph-base--Wk2dR";
export var arParagraphBaseMedium = "ArBrokerFeatures-module--ar-paragraph-base-medium--21Zri";
export var arParagraphSmall = "ArBrokerFeatures-module--ar-paragraph-small--2QxTh";
export var arParagraphSmallMedium = "ArBrokerFeatures-module--ar-paragraph-small-medium--18aOO";
export var arParagraphXsmall = "ArBrokerFeatures-module--ar-paragraph-xsmall--MZ3Vi";
export var arParagraphXsmallMedium = "ArBrokerFeatures-module--ar-paragraph-xsmall-medium--3wqIX";
export var arLinkMobile = "ArBrokerFeatures-module--ar-link-mobile--3n80g";
export var arLinkDesktop = "ArBrokerFeatures-module--ar-link-desktop--ifVG-";
export var arQuote = "ArBrokerFeatures-module--ar-quote--MnaBK";
export var arQuoteMobile = "ArBrokerFeatures-module--ar-quote-mobile--N451o";
export var arGradientLight = "ArBrokerFeatures-module--ar-gradient-light--lZ0dz";
export var arGradientBlue = "ArBrokerFeatures-module--ar-gradient-blue--33_bh";
export var arTransition = "ArBrokerFeatures-module--ar-transition--1twYb";
export var container = "ArBrokerFeatures-module--container--3yCmJ";
export var admincontainer = "ArBrokerFeatures-module--admincontainer--2SvVP";