// extracted by mini-css-extract-plugin
export var arH1Mobile = "ArBrokerHero-module--ar-h1-mobile--3EI4d";
export var arH2Mobile = "ArBrokerHero-module--ar-h2-mobile--3ZV1N";
export var arH3Mobile = "ArBrokerHero-module--ar-h3-mobile--1JoXQ";
export var arH4Mobile = "ArBrokerHero-module--ar-h4-mobile--33_yn";
export var arH5Mobile = "ArBrokerHero-module--ar-h5-mobile--3F9Yc";
export var arH1 = "ArBrokerHero-module--ar-h1--1_Vjd";
export var arH2 = "ArBrokerHero-module--ar-h2--2vrMa";
export var arH3 = "ArBrokerHero-module--ar-h3--AT_fo";
export var arH4 = "ArBrokerHero-module--ar-h4--23VCL";
export var arH5 = "ArBrokerHero-module--ar-h5--3lckm";
export var arParagraphXl = "ArBrokerHero-module--ar-paragraph-xl--aecyb";
export var arParagraphXlMedium = "ArBrokerHero-module--ar-paragraph-xl-medium--QTYFW";
export var arParagraphBase = "ArBrokerHero-module--ar-paragraph-base--1iuzz";
export var arParagraphBaseMedium = "ArBrokerHero-module--ar-paragraph-base-medium--3IJgG";
export var arParagraphSmall = "ArBrokerHero-module--ar-paragraph-small--1OjwM";
export var arParagraphSmallMedium = "ArBrokerHero-module--ar-paragraph-small-medium--1mGro";
export var arParagraphXsmall = "ArBrokerHero-module--ar-paragraph-xsmall--4OVPa";
export var arParagraphXsmallMedium = "ArBrokerHero-module--ar-paragraph-xsmall-medium--2pNer";
export var arLinkMobile = "ArBrokerHero-module--ar-link-mobile--3myxg";
export var arLinkDesktop = "ArBrokerHero-module--ar-link-desktop--3XAYh";
export var arQuote = "ArBrokerHero-module--ar-quote--jrHT-";
export var arQuoteMobile = "ArBrokerHero-module--ar-quote-mobile--2_8-2";
export var arGradientLight = "ArBrokerHero-module--ar-gradient-light--AvQ0u";
export var arGradientBlue = "ArBrokerHero-module--ar-gradient-blue--2AkvK";
export var arTransition = "ArBrokerHero-module--ar-transition--1eajS";
export var container = "ArBrokerHero-module--container--3B8gI";